import { IThirdStageLung } from './thirdStage';

export enum CaseStatus {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  WAIT = 'WAIT',
}

export interface IDraftCaseLung extends IThirdStageLung {
  id: number;
  comment: string;
  step: number;
  title: string;
  status: CaseStatus;
  yearOfBirth: number;
  gender: string;
  patientCity: string;
  patientVoivodeship: {
    id: number;
    name: string;
  },
  weight: number;
  height: number;
  bmi: number;
  chronicDisease: {
    cardiovascularDiseases: string[];
    cardiovascularDiseaseOther: string
    digestiveSystemDisease: string
    urogenitalDisease: string
    isPochp: boolean
    isAstma: boolean
    respiratorySystemDisease: string,
    connectiveTissueDisease: string,
    diabetes: string,
    hypothyroidism: string,
    psoriasis: string,
    albinism: string,
    cancerDiseaseTreated: string,
    cancerDiseaseRadicalTreatment: string,
    lungCancer: string,
    other: string,
  };
  city: string;
  voivodeship: {
    id: number;
    name: string;
  },
  interview: {
    smockingTobacco: {
      isActive: boolean
      years: number
      amount: number
      packYear: number
    },
    cough: {
      isActive: boolean
      isChronic: boolean
      dateFrom: string
      lastQuarterChange: string
    },
    hemoptysis: {
      isActive: boolean,
      dateFrom: string
    },
    weightLoss: {
      status: string
      dateFrom: string
      weight: number
    },
    hoarseness: {
      isActive: true
      dateFrom: string
    },
    dyspnoea: {
      isActive: boolean
      dateFrom: string
    },
    otherSymptoms: string,
    carcinogenicFactors: {
      status: string,
      which: string
    },
    statusECOG: string,
    histologicalCancer: string
  };
  diagnosis: {
    molecularChanges: {
      isAlk: boolean | null,
      isEgfr: boolean | null,
      isRos: boolean | null,
    },
    ngs: string | null,
    expressionLevel: {
      level: string | null,
      percent: string | null,
    },
    tnm: string | null,
    metastases: {
      status: string | null,
      isCns: boolean | null,
      which: string | null,
    },
    problems: {
      list: string[],
      others: string[]
    },
    dilo: string | null,
  };
}

