import { useAuthStore, useDraftCaseStore, useProgressBarNeuroStore } from '../../../../../../store/hooks';
import { Stages } from '../../progressBar/ProgressBar.helper';
import { useDraftCaseApi } from '../../../../../../apiHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { generateLink, UserLink } from '../../../../../../utils';
import { RoleCategory } from '../../../../../../constants/projectUsers';
import { AddCaseActionStatus } from '../../../../../../constants/addCaseStatus';
import { useEffect, useState } from 'react';

export const useFifthStage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { publishDraftCase } = useDraftCaseApi();
  const progressBarStore = useProgressBarNeuroStore();
  const draftCaseStore = useDraftCaseStore();
  const { areas } = useAuthStore();
  const { abbreviationNeuro } = draftCaseStore;
  const [isBlocked, setIsBlocked] = useState(false);
  const areaStatus =
    areas && areas?.find(el => el.shortName.toLowerCase() === abbreviationNeuro.toLowerCase())?.addCaseActionStatus;

  useEffect(() => {
    setIsBlocked(areaStatus === AddCaseActionStatus.BLOCKED);
  }, [areaStatus]);

  const sentToAcceptHandler = async () => {
    if ([AddCaseActionStatus.OPEN, AddCaseActionStatus.CLOSE_TO_BLOCKED].includes(areaStatus as AddCaseActionStatus)) {
      await clickButtonHandler('publish');
    }
  };

  const clickButtonHandler = async (type: string) => {
    progressBarStore.setTypeOfCase(type);
    progressBarStore.setCurrentStage(Stages.SIXTH);

    if (type === 'publish' && draftCaseStore.draftCaseNeuro) {
      await publishDraftCase(draftCaseStore.draftCaseNeuro.id);
    }

    progressBarStore.setTypeOfCase(type);
    progressBarStore.setCurrentStage(Stages.SIXTH);
  };

  const navigateToView = () => {
    navigate(generateLink(RoleCategory.USER, UserLink.VIEW_CASE_NEURO, id));
  };

  const draftHandler = async () => {
    await clickButtonHandler('draft');
  };

  return { draftHandler, navigateToView, isBlocked, sentToAcceptHandler };
};
