import { useAuthStore, useDraftCaseStore, useProgressBarLungStore } from '../../../../../../store/hooks';
import { Stages } from '../../progressBar/ProgressBar.helper';
import { useNavigate, useParams } from 'react-router-dom';
import { generateLink, UserLink } from '../../../../../../utils';
import { RoleCategory } from '../../../../../../constants/projectUsers';
import { useEffect, useState } from 'react';
import { AddCaseActionStatus } from '../../../../../../constants/addCaseStatus';
import { useDraftCaseApi } from '../../../../../../apiHooks/useDraftCaseLungApi';
import { CaseStatus } from '../../../../../../interfaces/user/draftCaseLung/draft';

export const useFifthStage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { publishDraftCase } = useDraftCaseApi();
  const progressBarStore = useProgressBarLungStore();
  const draftCaseStore = useDraftCaseStore();
  const { areas } = useAuthStore();
  const { abbreviationLung } = draftCaseStore;
  const [isBlocked, setIsBlocked] = useState(false);

  const areaStatus =
    areas && areas?.find(el => el.shortName.toLowerCase() === abbreviationLung.toLowerCase())?.addCaseActionStatus;

  const status = draftCaseStore.draftCaseLung && draftCaseStore.draftCaseLung.status === CaseStatus.DRAFT;

  useEffect(() => {
    setIsBlocked(true); // BLOCKED areaStatus === AddCaseActionStatus.BLOCKED
  }, [areaStatus, status]);

  const sentToAcceptHandler = async () => {
    if ([AddCaseActionStatus.OPEN, AddCaseActionStatus.CLOSE_TO_BLOCKED].includes(areaStatus as AddCaseActionStatus)) {
      await clickButtonHandler('publish');
    }
  };

  const clickButtonHandler = async (type: string) => {
    progressBarStore.setTypeOfCase(type);
    progressBarStore.setCurrentStage(Stages.SIXTH);

    if (type === 'publish' && draftCaseStore.draftCaseLung) {
      await publishDraftCase(draftCaseStore.draftCaseLung.id);
    }

    progressBarStore.setTypeOfCase(type);
    progressBarStore.setCurrentStage(Stages.SIXTH);
  };

  const navigateToView = () => {
    navigate(generateLink(RoleCategory.USER, UserLink.VIEW_CASE_LUNG, id));
  };

  const draftHandler = async () => {
    await clickButtonHandler('draft');
  };

  return { draftHandler, navigateToView, isBlocked, sentToAcceptHandler };
};
